export default function quizPopup(){
	let quizButton = document.querySelector('.quiz-button');

	if(!quizButton){
		return;
	}

	quizButton.addEventListener('click', function(){
		document.documentElement.classList.toggle('prestart-open');
	});

	let quizClose = document.querySelector('.quiz-prestart [data-close]');
	quizClose.addEventListener('click', function(){
		document.documentElement.classList.remove('prestart-open');
	});
}