import Masonry from 'masonry-layout'

export function goalTiles() {
	let initialTiles = document.querySelectorAll('.goalfilters__initial .goal-tile, .goalfilters__initial .about-tile-alt');
	let goalFilters = document.querySelector('.goalfilters');

	if(!initialTiles.length || !goalFilters){
		return;
	}

	// init masonry on initial tiles
	let masonryGrid = document.querySelector('.goalfilters__initial');
	let msnry = new Masonry( masonryGrid, {
		itemSelector: '.goalfilters__col'
	});

	let resetButton = goalFilters.querySelector('[data-reset]');
	resetButton.addEventListener('click', function(){
		_resetFilters();
	});

	// handle auto loading via URL
	let endColActive = document.querySelector('.goalfilters__end__col.active');
	if(endColActive){
		let currentActiveTile = document.querySelector('.goalfilters__col.active');

		if(currentActiveTile && currentActiveTile.dataset.layout == "1"){
			goalFilters.classList.add('end-active');
		}
	}

	initialTiles.forEach(function(el, index){
		el.addEventListener('click', function(e){
			e.preventDefault();

			let thisIndex = el.parentNode.dataset.triggerSlide;
			let thisLayout = el.parentNode.dataset.layout;
			let middleCol = document.querySelector('.goalfilters__middle__col[data-trigger-slide="'+thisIndex+'"]');

			if(el.parentNode.classList.contains('active')){
				el.parentNode.classList.remove('active');
				middleCol.classList.remove('active');
				goalFilters.classList.remove('initial-active');
				goalFilters.classList.remove('end-active');
				msnry.layout();

				let endColumn = document.querySelector('.goalfilters__end__col.active');
				if(endColumn){
					endColumn.classList.remove('active');
				}

				setTimeout(function(){
					msnry.layout();
				}, 400);
			}else{
				let allInitialTiles = document.querySelectorAll('.goalfilters__col.active');
				allInitialTiles.forEach(function(el, index){
					el.classList.remove('active');
				});

				let allMiddleTiles = document.querySelectorAll('.goalfilters__middle__col.active');
				allMiddleTiles.forEach(function(el, index){
					el.classList.remove('active');
				});

				let allEndTiles = document.querySelectorAll('.goalfilters__end__col.active');
				allEndTiles.forEach(function(el, index){
					el.classList.remove('active');
				});

				el.parentNode.classList.add('active');
				middleCol.classList.add('active');
				goalFilters.classList.add('initial-active');
				msnry.layout();

				if(thisLayout == 1){
					// show end column off the bat
					let endColumn = document.querySelector('.goalfilters__end__col[data-trigger-slide="'+thisIndex+'"]');
					endColumn.classList.add('active');
					goalFilters.classList.add('end-active');

					let firstAccordion = endColumn.querySelector('.accordion__title.first');
					if(firstAccordion){
						setTimeout(function(){
							_openAccordion(firstAccordion);
						}, 400);
					}
				}

				if(thisLayout == 2){
					// populate selected option span in middle & end
					let selectedOptionSpans = document.querySelectorAll('[data-selected]');

					if(selectedOptionSpans.length){
						selectedOptionSpans.forEach(function(el, index){
							el.textContent = document.querySelector('.goalfilters__col.active h4').textContent;
						});
					}
				}

				setTimeout(function(){
					msnry.layout();
				}, 400);
			}
		});
	});

	let layoutTwoLinks = document.querySelectorAll('.goalfilters__services [data-option]');

	if(layoutTwoLinks.length){
		layoutTwoLinks.forEach(function(el, index){
			let thisMajorSlide = el.closest('[data-trigger-slide]').dataset.triggerSlide;

			el.addEventListener('click', function(){
				let thisEndGoal = document.querySelector('.goalfilters__end__col[data-trigger-slide="'+thisMajorSlide+'"]');
				thisEndGoal.classList.add('active');
				goalFilters.classList.add('end-active');

				layoutTwoLinks.forEach(function(el2, index2){
					el2.classList.remove('active');
				});

				this.classList.add('active');

				let thisDataOption = el.dataset.option;

				let allServiceOptions = thisEndGoal.querySelectorAll('.service-option');
				allServiceOptions.forEach(function(el3, index3){
					if(el3.dataset.serviceOption == thisDataOption){
						el3.classList.add('active');
					}else{
						el3.classList.remove('active');
					}
				});
			});
		});
	}

	function _resetFilters(){
		initialTiles.forEach(function(el, index){
			let thisIndex = el.parentNode.dataset.triggerSlide;
			let thisLayout = el.parentNode.dataset.layout;
			let middleCol = document.querySelector('.goalfilters__middle__col[data-trigger-slide="'+thisIndex+'"]');

			if(el.parentNode.classList.contains('active')){
				el.parentNode.classList.remove('active');
				middleCol.classList.remove('active');
				goalFilters.classList.remove('initial-active');
				goalFilters.classList.remove('end-active');
				msnry.layout();

				let endColumn = document.querySelector('.goalfilters__end__col.active');
				if(endColumn){
					endColumn.classList.remove('active');
				}

				setTimeout(function(){
					msnry.layout();
				}, 400);
			}
		});
	}

	let resizeTimer;
	function onResizeComplete() {
		msnry.layout();
	}
	window.addEventListener('resize', function() {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(onResizeComplete, 300); // Adjust the delay as needed (in milliseconds)
	});
}