export function processLine(){
	let lineContainer = document.querySelector('.process__linecontainer');

	if(!lineContainer){
		return;
	}

	let windowHeight = window.innerHeight;
	var windowScrollTop = window.scrollY;
	var svgPaths = document.querySelectorAll(".svg-path");
	var scrollOffset = windowHeight * .3; 

	svgPaths.forEach(function(el, index){
	    var pathLength = el.getTotalLength();

	    // Make very long dashes (the length of the path itself)
	    el.style.strokeDasharray = pathLength + ' ' + pathLength;
	    // Offset the dashes so the it appears hidden entirely
	    el.style.strokeDashoffset = pathLength;
	});

	_animateLineBlocker();

	window.addEventListener('scroll', function(){
		_animateLineBlocker();
	});

	window.addEventListener('resize', function(){
		windowHeight = window.innerHeight;
		scrollOffset = windowHeight * .3; 
	});

	function _animateLineBlocker(){
		windowScrollTop = window.scrollY;

		svgPaths.forEach(function(el, index){
			var thisScrollOffset = scrollOffset;

			if(el.dataset.customOffset){
				thisScrollOffset = windowHeight * el.dataset.customOffset;
			}	

			var elementRect = el.getBoundingClientRect();
	    	var pathLength = el.getTotalLength();
	    	var scrollPercentage = (windowHeight - (elementRect.y + scrollOffset)) / elementRect.height;
	        var drawLength = scrollPercentage * pathLength;


	        if (drawLength < 0) {
		      drawLength = 0;
		    } else if (drawLength > pathLength) {
		      drawLength = pathLength;
		    }

    el.style.strokeDashoffset = pathLength - drawLength;
	        _showOptions();
        });
	}

	/**
	 * Shows the tiles as you scroll
	 */
	function _showOptions(){
		let options = document.querySelectorAll('.process__item');

		options.forEach(function(el, index){
			if((windowHeight - (el.getBoundingClientRect().y + (scrollOffset * 1.1))) > 0){
				el.classList.add('shown');
			}else{
				el.classList.remove('shown');
			}
		});
	}
}

export function processClicks(){
	let options = document.querySelectorAll('[data-option]');
	let lineContainer = document.querySelector('.process__linecontainer');

	if(!options.length || !lineContainer){
		return;
	}

	options.forEach(function(el,index){
		el.addEventListener('click', function(){
			_deselectAll(options);

			let selectedOption = el.dataset.option;
			let selectedPaths = document.querySelectorAll('[data-path="'+selectedOption+'"]');
			lineContainer.classList.remove('open-1');
			lineContainer.classList.remove('open-2');
			lineContainer.classList.add('open-'+selectedOption);

			el.classList.add('active');

			selectedPaths.forEach(function(el, index){
				el.classList.add('active');
			});

			window.dispatchEvent(new CustomEvent('scroll'))
		});
	});	

	function _deselectAll(options){

		options.forEach(function(el, index){
			el.classList.remove('active');
		});

		let allPaths = document.querySelectorAll('[data-path]');
		allPaths.forEach(function(el, index){
			el.classList.remove('active');
		});
	}
}

export function processItems(){
	let processItems = document.querySelectorAll('.process__item');

	if(!processItems.length){
		return;
	}

	processItems.forEach(function(el, index){
		el.addEventListener('click', function(){
			el.classList.toggle('show-text');
		});
	});
}
