export default function tabs(){
	let tabHeadings = document.querySelectorAll('.swiper-slide[data-tab]');
	let tabContents = document.querySelectorAll('.tabs__contents__item[data-tab]');

	let tabFilterTabs = document.querySelectorAll('[data-tabfilter]');
	let allPageTitleBGs = [];
	let pageTitleImageContainer = document.querySelector('.section-pagetitle__image');

	tabHeadings.forEach(function(el, index){
		if(el.dataset.pageimage){
			allPageTitleBGs[index] = el.dataset.pageimage;
		}		

		el.addEventListener('click', function(){
			let tabIndex = el.dataset.tab;
			let tab = el.dataset.tab;
			let selectedTabs = document.querySelectorAll('.tabs__contents__item[data-tab="'+tab+'"]');

			_removeOthers();

			el.classList.add('active');

			selectedTabs.forEach(function(el2, index2){
				el2.classList.add('active');
			});

			let connectedImage = pageTitleImageContainer.querySelector('[data-index="'+index+'"]');
			if(connectedImage){
				connectedImage.setAttribute('src', connectedImage.dataset.src);
				_hideAllOtherImages();

				connectedImage.classList.add('is-active');
				pageTitleImageContainer.classList.add('custom-active');
			}else{
				pageTitleImageContainer.classList.remove('custom-active');
				_hideAllOtherImages();
			}
		});
	});


	tabFilterTabs.forEach(function(el, index){
		el.addEventListener('click', function(){
			let tabFilter = el.dataset.tabfilter;

			_removeOtherTabFilters();

			el.closest('.tabs__headings').dataset.activeFilter = tabFilter;

			if(el.closest('.swiper-slide')){
				el.closest('.swiper-slide').remove();
			}

			allSwipers[0].update();
			allSwipers[0].slideTo(2);
		});
	});

	// handle toggling page title image bg on tab change, add them all here them show them later
	if(pageTitleImageContainer){
		allPageTitleBGs.forEach((img, index) => {
			pageTitleImageContainer.insertAdjacentHTML('beforeend','<img data-index="'+index+'" data-src="'+img+'"/>');
		});
	}

	// handle auto opening tabs on load
	const urlParams = new URLSearchParams(window.location.search);
	const stepParam = urlParams.get('step');

	if(stepParam){
		let stepHeading = document.querySelector('[data-tab="'+(stepParam-1)+'"]');

		if(stepHeading){
			let tabFilter = stepHeading.closest('[data-filter]');

			setTimeout(function(){
				if(tabFilter){
					let tabFilterButton = document.querySelector('[data-tabfilter="'+tabFilter.dataset.filter+'"]');

					if(tabFilterButton){
						tabFilterButton.click();
					}
				}	

				stepHeading.click();
			}, 100);
		}
	}

	function _hideAllOtherImages(){
		let allOtherImages = document.querySelectorAll('.is-active');
		allOtherImages.forEach((img) => {
			img.classList.remove('is-active');
		});
	}

	function _removeOthers(){
		tabHeadings.forEach(function(el, index){
			el.classList.remove('active');
		});
		tabContents.forEach(function(el, index){
			el.classList.remove('active');
		});
	}

	function _removeOtherTabFilters(){
		
	}
}