import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import Swiper, { Navigation, Pagination, Autoplay, Controller, Mousewheel, FreeMode} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, Controller, Mousewheel, FreeMode])

export default function sliders() {
    // get all sliders, we need to loop them due to different settings + nav
    var swipers = document.querySelectorAll(
        '.swiper:not(.control):not(.mobile)'
    )

    window.allSwipers = [];
    
    let runningTimeout = 0;

    const urlParams = new URLSearchParams(window.location.search);
    const stepParam = urlParams.get('step');

    swipers.forEach(function (el, index) {
        var closestSection = el.closest('section')
        var controls = closestSection.querySelector('.control')


        // slider settings
        var options = {
            speed: 600,
            loop: true,
            simulateTouch:false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            navigation: {
                nextEl: closestSection.querySelector('.swiper-next'),
                prevEl: closestSection.querySelector('.swiper-prev'),
            },
            pagination: {
                el: closestSection.querySelector('.swiper-pagination'),
                clickable: true,
            },
            controller: {},
            slidesPerView: 1
        }

        if(closestSection.classList.contains('section-categorysliders') || 
            closestSection.classList.contains('section-testimonials') || 
            closestSection.classList.contains('section-ourservices')){

            options = {
                speed: 1000,
                loop: true,
                autoplay: {
                    delay: 2000
                },
                slidesPerView : 'auto',
                spaceBetween : 30,
                navigation: {
                    nextEl: el.parentNode.querySelector('.swiper-next'),
                    prevEl: el.parentNode.querySelector('.swiper-prev'),
                }
            };
        }

        if(closestSection.classList.contains('section-tabs')){

            options = {
                speed: 800,
                loop: false,
                slidesPerView : 2,
                spaceBetween : 0,
                autoplay: false,
                freeMode : {
                    momentumVelocityRatio: 0.6
                },
                mousewheel: {
                    enabled: true,
                    forceToAxis: true
                },
                navigation: {
                    nextEl: el.parentNode.querySelector('.swiper-next'),
                    prevEl: el.parentNode.querySelector('.swiper-prev'),
                },
                breakpoints: {
                    768:{
                        slidesPerView: 3
                    },
                    992: {
                        slidesPerView: 4
                    }
                }
            };
        }

        if(el.dataset.slides){
            options.spaceBetween = 24;

            options.breakpoints = {
                640: {
                    slidesPerView: el.dataset.slides,
                },
                992: {
                    slidesPerView: el.dataset.slides,
                }
            }
        }

        setTimeout(function(){

            var mainSwiper = new Swiper(el, options);

            // For gallery sliders
            if (controls) {
                var swiperThumbs = new Swiper(controls, {
                    speed: 600,
                    loop: true,
                    slidesPerView: 1,
                    autoplay: false,
                    controller: {
                        control: mainSwiper
                    }
                });

                mainSwiper.controller.control = swiperThumbs;
            }

            if(!el.parentNode.classList.contains('section-hero__content')){
                if(closestSection.classList.contains('section-categorysliders') || 
                closestSection.classList.contains('section-testimonials') || 
                closestSection.classList.contains('section-ourservices')){
                    el.addEventListener('mouseenter', function() {
                        mainSwiper.autoplay.stop();
                    });
                    el.addEventListener('mouseleave', function() {
                        mainSwiper.autoplay.start();
                    });
                }
            }

            if(stepParam){
                mainSwiper.slideTo(stepParam);
            }

            window.allSwipers.push(mainSwiper);


        }, runningTimeout);

        runningTimeout += 200;

        // init slider
    })
}
