export function cf7Focus() {
    const inputs = document.querySelectorAll('.wpcf7-form-control');

    function handleInputChange(input) {
        const label = input.closest('.form-column').querySelector('label');
        if (label && input.value.trim() !== '') {
            label.classList.add('active');
        } else if (label) {
            label.classList.remove('active');
        }
    }

    inputs.forEach((input) => {
        ['input', 'focus'].forEach((event) => {
            input.addEventListener(event, () => handleInputChange(input));
        });
        handleInputChange(input);
    });
}
