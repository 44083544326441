export default function menu(){
	var hamburgerMenu  = document.querySelector('.site-header__hamburger');
	var mobNavItems = document.querySelectorAll('.site-header__nav a');

	hamburgerMenu.addEventListener('click', function(){
		document.documentElement.classList.toggle('menu-open');
	});

	mobNavItems.forEach(function(el, index){
		el.addEventListener('click', function(){
			document.documentElement.classList.remove('menu-open');
		});
	});


	// subnav
	var subNavItems = document.querySelectorAll('.site-header__menus ul > li > a span');
	subNavItems.forEach(function(el, index){
		el.addEventListener('click', function(e){
			e.preventDefault();

			el.closest('li').classList.toggle('active');
		});	
	});
}