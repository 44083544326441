export default function accordion() {
    window.slideDown = elem => elem.style.height = `${elem.scrollHeight}px`;
    window.slideUp = elem => elem.style.height = `0px`;

    let accordionTitles = document.querySelectorAll('.accordion__title');
    let activeContent = getActiveContentEl();

    if(activeContent){
    	slideDown(activeContent);
    }

    _toggleAccordions();
    	

    function resizedw(){
        _toggleAccordions();
    }

    var doit;
    window.onresize = function(){
        clearTimeout(doit);
        doit = setTimeout(resizedw, 100);
    };

    function _toggleAccordions() {
        accordionTitles.forEach(function(el, index){
            el.addEventListener('click', function(){
                if(el.classList.contains('active')){
                    let thisContent = getActiveContentEl();
                    el.classList.remove('active');
                    slideUp(thisContent);
                }else{
                    _openAccordion(el);
                }
            });
        });
    }

    window._openAccordion = function(el){
        // hide active el
        let activeTitle = getActiveTitleEl();

        if(activeTitle){
            let alreadyActive = getActiveContentEl();
            slideUp(alreadyActive);
            activeTitle.classList.remove('active');
        }

        el.classList.add('active');
        let thisContent = getActiveContentEl();
        slideDown(thisContent);
    }

    function getActiveTitleEl(){
        return document.querySelector('.accordion__title.active');
    }

    function getActiveContentEl(){
    	return document.querySelector('.accordion__title.active + .accordion__content');
    }
}
